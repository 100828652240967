<template>
  <div>
    <div class="z-0 pt-48">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="text-gray-300">
          <div class="">
            <div class="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2
                  class="text-5xl font-extrabold sm:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                >
                  Term of Service
                </h2>
                <dd
                  class="mt-4 text-base text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200 text-xl"
                >
                  Welcome to PromptDue! These Terms of Service ("Terms") govern
                  your access to and use of the PromptDue website (the "Site")
                  and services (the "Services"). By using the Site and Services,
                  you agree to be bound by these Terms. If you do not agree to
                  these Terms, please do not use our Site or Services.
                </dd>
              </div>
              <div class="col-span-2 mt-10 lg:mt-0">
                <dl class="space-y-10">
                  <div></div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Description of Services
                    </dt>
                    <dd class="mt-2 text-base text-gray-00">
                      PromptDue is a marketplace for buying and selling prompt
                      arts created from GPT-3, ChatGPT, Dall-E, Midjourney,
                      Stable Diffusion, Promptbase, Blue Willow, and other
                      related artificial intelligence tools. PromptDue provides
                      a platform for users to create, share, and purchase prompt
                      arts, and for sellers to receive 80% of each prompt sold
                      while PromptDue receives 20% to run the platform and
                      provide support and services to users.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      User Accounts
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      User Accounts To use certain features of the Site and
                      Services, you may need to create a user account. When
                      creating an account, you must provide accurate and
                      complete information, and you are responsible for
                      maintaining the confidentiality of your account
                      information, including your username and password. You
                      agree to immediately notify PromptDue of any unauthorized
                      use of your account or any other breach of security.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      User Content
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      You are solely responsible for the content you create,
                      post, upload, or transmit through the Site and Services
                      ("User Content"). You retain ownership of your User
                      Content, but by submitting User Content to PromptDue, you
                      grant us a non-exclusive, worldwide, perpetual,
                      irrevocable, royalty-free, sublicensable right to use,
                      reproduce, modify, distribute, and display your User
                      Content in connection with the Site and Services.
                      <br />
                      <br />
                      You agree not to post or upload User Content that is
                      illegal, defamatory, harassing, discriminatory, or
                      otherwise objectionable, or that infringes on the
                      intellectual property rights of others. PromptDue reserves
                      the right to remove any User Content that we deem
                      inappropriate or objectionable, and to terminate the
                      accounts of users who violate these Terms.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Third-Party Websites and Services
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Our Site and Services may contain links to third-party
                      websites and services, which may have their own privacy
                      policies and practices. We are not responsible for the
                      privacy practices of these third-party websites and
                      services, and we encourage you to review their privacy
                      policies before providing them with any information.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Transactions
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      As a buyer or seller on PromptDue, you agree to abide by
                      the following terms:

                      <br />
                      <br />
                      Buyers: When purchasing a prompt, you agree to pay the
                      listed price and any applicable fees or taxes. You are
                      responsible for reviewing and accepting the prompt before
                      completing the transaction. PromptDue does not guarantee
                      the accuracy, quality, or legality of any prompt, and is
                      not responsible for any disputes between buyers and
                      sellers.

                      <br />
                      <br />

                      Sellers: When selling a prompt, you agree to provide
                      accurate and complete information about the prompt,
                      including the prompt's title, description, and any
                      applicable tags or categories. You agree to sell the
                      prompt at the listed price and to deliver the prompt to
                      the buyer upon completion of the transaction. You are
                      responsible for ensuring that you have the necessary
                      rights to sell the prompt, and for complying with all
                      applicable laws and regulations. Fees PromptDue charges a
                      20% fee on each prompt sold on the Site. The remaining 80%
                      will be paid to the seller. PromptDue reserves the right
                      to change its fee structure at any time, and will provide
                      notice of any changes in advance.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Intellectual Property
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      PromptDue and its licensors own all intellectual property
                      rights in the Site and Services, including the design,
                      layout, content, and software. You may not reproduce,
                      modify, distribute, or display any part of the Site or
                      Services without our prior written consent.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Termination
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      PromptDue may terminate your access to the Site and
                      Services at any time, without notice, for any reason or no
                      reason at all. Upon termination, you will no longer have
                      access to your account or any User Content or other
                      information associated withyour account. PromptDue may
                      also remove any User Content that violates these Terms, or
                      that is otherwise objectionable or inappropriate.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Limitation of Liability
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      PromptDue is not liable for any damages or losses arising
                      from your use of the Site and Services, including any
                      direct, indirect, incidental, consequential, or punitive
                      damages, even if we have been advised of the possibility
                      of such damages. PromptDue is not responsible for the
                      actions or omissions of any user or third party, including
                      any user-generated content or intellectual property. Our
                      liability for any claims arising from your use of the Site
                      and Services is limited to the fees paid by you to
                      PromptDue in connection with the transaction giving rise
                      to the claim.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Indemnification
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      You agree to indemnify, defend, and hold PromptDue and its
                      affiliates, officers, directors, employees, agents, and
                      licensors harmless from any claims, liabilities, damages,
                      losses, and expenses, including reasonable attorneys'
                      fees, arising from your use of the Site and Services, your
                      User Content, or your violation of these Terms or any
                      applicable law or regulation.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Modifications to Terms
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      PromptDue reserves the right to modify these Terms at any
                      time, without notice, by posting the modified Terms on the
                      Site. Your continued use of the Site and Services
                      following the posting of the modified Terms constitutes
                      your acceptance of the changes.
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Governing Law
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      These Terms shall be governed by and construed in
                      accordance with the laws of [insert governing law],
                      without giving effect to any principles of conflicts of
                      law. Any legal action or proceeding arising from these
                      Terms or your use of the Site and Services shall be
                      brought exclusively in the courts of [insert
                      jurisdiction].
                    </dd>
                  </div>

                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Miscellaneous
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      These Terms constitute the entire agreement between you
                      and PromptDue regarding the Site and Services, and
                      supersede any prior or contemporaneous agreements,
                      communications, or understandings, whether written or
                      oral. If any provision of these Terms is found to be
                      invalid or unenforceable, the remaining provisions shall
                      remain in full force and effect. No waiver of any
                      provision of these Terms shall be deemed a further or
                      continuing waiver of such provision or any other
                      provision, and PromptDue's failure to assert any right or
                      provision under these Terms shall not constitute a waiver
                      of such right or provision.
                    </dd>
                  </div>

                  <h2
                    class="text-2xl font-extrabold sm:text-2xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                  >
                    Credit : ChateGPT
                  </h2>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickFooter />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

export default {
  data() {
    return {
      products: [],
      products_2: [],
      prompts: [],
    };
  },
  components: { ConnectWallet, StickFooter },
  methods: {},
  created() {},
};
</script>
